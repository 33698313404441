import React from 'react'
import { ModalTips, ViewTransation, ModalSelectAsset } from '../../components/littleComponents'
import styles from "./ModalSucAndFail.module.scss";


// 默认只有一个按钮关闭

export const ModalSucAndFail = (props) => {
  const {
    type,
    showTitle = true,
    title,
    content,
    txnHash,
    showTransation = true,
    handleOk = () => { },
    okText = '',
    footerList = '',
    headerInfo = '',
    isSelectAsset = false,
    swapData,
    swapAsset,
    handleSelectAsset,
  } = props

  return (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.formContainer}>
          {headerInfo}
          {
            isSelectAsset &&
            <div style={{ 'marginBottom': '20px' }}>
              <ModalSelectAsset
                swapData={swapData}
                swapAsset={swapAsset}
                onClick={handleSelectAsset}
              />
            </div>
          }
          <ModalTips
            type={type || 'success'}
            showTitle={showTitle}
            title={title}
            content={content}
          />

          <p />

          {showTransation && <ViewTransation txnHash={txnHash} />}

          <p>&nbsp;</p>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        {
          okText &&
          <button
            className={styles.btn}
            onClick={handleOk}>
            {okText}
          </button>
        }
      </div>
      {footerList}
    </>
  )
}