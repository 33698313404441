import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import Config from '../../utils/config';
import { LanguageContext } from '../../context'
import FreshBankText from '../../images/logo_white.png';
import PeckShieldLogo from '../../images/freshBank/peckshield.png'
import styles from './commonFooter.module.scss'

export const CommonFooter = props => {
  const { t, i18n } = useTranslation()
  const { language, setLanguage } = useContext(LanguageContext)
  const { pathname } = useLocation()

  useEffect(() => {
    if (i18n.language) {
      let curLang = i18n.language.startsWith('zh') ? 'zh-TW' : i18n.language.split('-')[0];

      if (Config.LanguageList.findIndex(item => item.key === curLang) < 0) {
        curLang = "en";
      }

      setLanguage(curLang)
      i18n.changeLanguage(curLang)
    }
  }, [i18n.language])

  // render UI
  // const footerNav = () => {
  //   return Config.navList.map((i) => {

  //     const validDate = !i.startDate || new Date(i.startDate).getTime() < new Date().getTime()

  //     return i.footerShow && validDate && (
  //       <dd key={i.name}>
  //         <a
  //           className={styles.link}
  //           href={i.sameDomain ? '/' + i18n.language + i.path : i.path}
  //           target={i.isBlank ? '_blank' : '_self'}
  //         >{t(i.txt)}</a>
  //       </dd>
  //     )
  //   })
  // }

  // const renderCommunity = () => {
  //   return Config.community.map(item => {

  //     const aProps = {
  //       rel: 'nofollow',
  //       target: item.target || '_blank',
  //     }

  //     if (item.url === '#') {
  //       aProps.onClick = (e) => {
  //         e.stopPropagation()
  //       }
  //     } else {
  //       aProps.href = item.url
  //     }

  //     return (
  //       <dd key={item.name}>
  //         <a {...aProps}>
  //           {item.name}
  //         </a>
  //       </dd>
  //     )
  //   })
  // }

  return (
    <div className={styles.footer}>
      <Container className={styles.footerContainer}>
        <div className={styles.footerTop}>
          <div className={styles.logoWrap}>
            <a href={"/" + language}>
              <img
                className={styles.logo}
                src={FreshBankText}
                height="32"
                alt="Logo"
              />
            </a>
            {/* <a href="https://docs.torches.finance/docs/security-and-audit-report" target="_blank" className={styles.peck} rel="noopener noreferrer">
              <img
                src={PeckShieldLogo}
                width="117"
                height="36.5"
                alt="PeckShieldLogo"
              />
            </a> */}
          </div>

          <div className={styles.footerList}>
            <dl>
              <dt>{t('Footer.About')}</dt>

              <dd>
                <a href="#">Portal</a>
              </dd>

              <dd>
                <a
                  href="https://zksync.gitbook.io/nebu-finance"
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('Header.Nav.Docs')}
                </a>
              </dd>

              <dd>
                <a href="#" target="_blank" rel="noopener noreferrer">Governance Token</a>
              </dd>
            </dl>

            <dl>
              <dt>DApp</dt>

              {/* {footerNav()} */}
              <dd>
                <a
                  href="./crypto-bank"
                  target="_blank"
                  rel="noopener noreferrer">Supply & Borrow</a>
              </dd>

              <dd>
                <a
                  href="."
                  target="_blank"
                  rel="noopener noreferrer">GitHub</a>
              </dd>

              <dd>
                <a
                  href="."
                  target="_blank"
                  rel="noopener noreferrer">Audit Reports</a>
              </dd>
            </dl>

            <dl>
              {/* {renderCommunity()} */}
              <dd>
                <a
                  href="."
                  target="_blank"
                  rel="noopener noreferrer">Privacy Policy</a>
              </dd>
            </dl>
          </div>

          {/* <a
            href={"/" + language + "/crypto-bank"}
            className={styles.launchApp}>
            {t('Home.LaunchApp')}
          </a> */}
        </div>
        {/* <div className={styles.footerBottom}>
          <div className={styles.footerMessageContainer}>
            <div className={styles.footerMessage}>
              {t('Common.CopyRight')}
            </div>
          </div>
        </div> */}
      </Container>
    </div>
  )
}