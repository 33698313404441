import React from 'react'
import { TwinLogo } from '../littleComponents'

export const ModalHeader = ({
  data,
  title,
  customLogo = ''
}) => {

  return (
    <>
      {
        data ? (
          <>
            {customLogo ? customLogo :
              !data.isLPToken ? (
                <img
                  className="logo"
                  height="48px"
                  src={data?.logo}
                  alt={`${data?.name} Logo`} />
              ) : (
                <div className="logo">
                  <TwinLogo
                    mode={2}
                    logo1={data?.logo1}
                    logo2={data?.logo2}
                  />
                </div>
              )
            }
            <div className="assetName">{data?.name}</div>
          </>
        ) : title && (
          <div className="assetName">{title}</div>
        )
      }
    </>
  )
}