import React, { useContext } from 'react'
import { CommonHeader } from './components/headerV2/commonHeader'
import { CommonFooter } from './components/footerV2/commonFooter'

import styles from './App.module.scss'

const Master = ({
  children,
  headerLight = false
}) => {
  return (
    <div className={styles.App}>
      <CommonHeader headerLight={headerLight} />
      {children}
      <CommonFooter />
    </div>
  )
}


export default Master