import React, { useState, useEffect, lazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch, Redirect, useParams, useRouteMatch } from 'react-router-dom'
import Web3 from 'web3'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'
import { walletTypeChangeRequest, createWalletConnectWeb3Provider, getActiveWalletType, setActiveWalletType, switchDefaultChain } from './methods/Wallet';
import { LanguageContext, WalletAddressContext, WalletTypeContext, NetworkTypeContext, Web3Context, ReadonlyWeb3Context, DataContext, WalletModalContext } from './context'
import Config from './utils/config'
import FetchData from './methods/FetchData'
import { globalUtils } from './utils/globalUtils'
import Master from './Master'
import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk'
import { SafeAppProvider } from '@gnosis.pm/safe-apps-provider'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
const Home = lazy(() => import('./pages/homeV2/home')) // 首页
const BankV2 = lazy(() => import('./pages/bankV2/bank')) // 借贷页面
const MarketDetailV2 = lazy(() => import('./pages/MarketDetailV2/MarketDetail')) // 市场详情页
const LiquidateV2 = lazy(() => import('./pages/LiquidateV2/Liquidate')) // 清算页面 
const LockView = lazy(() => import('./pages/lock/Lock'));
const DAOView = lazy(() => import("./pages/dao/DAO"));
const NFT = lazy(() => import('./pages/nftV2/nft')) // NFT
// const StakingV2 = lazy(() => import('./pages/StakingV2/Staking')) // 抵押页面
// const VoteV2 = lazy(() => import('./pages/VoteV2/Vote')) // 投票

// 
const NoMatch = () => (
    <Redirect to="/crypto-bank" />
)

const Loading = (
    <div className="tor-suspenseLoading">
        <Spinner animation="border" variant="success" size="lg" />
    </div>
)

// 
let hecoMainNetWeb3 = null;
let theTimer = null;
let isLoading = false;
let previousAccount = "";
let specifiedAccount = "";
const defaultChain = Config.headerConfigData.network.zkSyncTest;

function LanguageAdapter(props) {
    const { i18n } = useTranslation();
    const { lang } = useParams();
    const match = useRouteMatch();
    const data = props.data;
    const [headerLight, setHeaderLight] = useState(false);
    const lo = useLocation();

    useEffect(() => {
        let preset = false;
        Config.LanguageList.forEach(item => {
            if (item.key === lang) {
                preset = true;
            }
        })

        if (preset) {
            i18n.changeLanguage(lang);
        } else {
            window.location = "/en";
        }
    }, []);

    const updateHeaderStyle = () => {
        setHeaderLight(lo.pathname === "/en/crypto-bank" || lo.pathname.indexOf("/en/markets/") === 0);
    };

    const renderRouter = () => {
        const routes = [
            {
                path: match.path,
                component: <Home
                    data={data}
                    inSafe={props.inSafe}
                    updateHeaderStyle={updateHeaderStyle} />
            },
            {
                path: match.path + "/crypto-bank",
                component: <BankV2
                    data={data}
                    inSafe={props.inSafe}
                    updateHeaderStyle={updateHeaderStyle} />
            },
            {
                path: match.path + '/markets/:symbol',
                component: <MarketDetailV2
                    data={data}
                    inSafe={props.inSafe}
                    updateHeaderStyle={updateHeaderStyle} />
            },
            // {
            //     path: '/staking',
            //     component: <StakingV2 data={data} />
            // },
            // {
            //     path: '/vote',
            //     component: <VoteV2/>
            // },
            {
                path: match.path + '/liquidation',
                component: <LiquidateV2 data={data} inSafe={props.inSafe} />
            },
            {
                path: match.path + '/nft',
                component: <NFT data={data} />,
                // startDate: Config.nftPageStartDate
            },
            {
                path: match.path + '/lock',
                component: <LockView data={data} />
            },
            {
                path: match.path + '/vote',
                component: <DAOView data={data} />
            },
            {
                path: '*',
                component: <NoMatch />
            }
        ];

        let routesPage = []
        for (let i = 0; i < routes.length; i++) {
            const item = routes[i]
            const validDate = !item.startDate || new Date(item.startDate).getTime() < new Date().getTime()
            if (validDate) {
                routesPage.push((
                    <Route exact path={item.path} key={item.path}>
                        <Master
                            defaultChain={defaultChain}
                            headerLight={headerLight}>
                            {item.component}
                        </Master>
                    </Route>
                ))
            }
        }
        return routesPage
    }

    return (<Switch>
        {props.curRoutes && renderRouter()}
    </Switch>);
}

let safeAppProvider = null;

function App() {
    const [connectedAddress, setConnectedAddress] = useState(globalUtils.NA);
    const [walletType, setWalletType] = useState()
    const [chainID, setChainID] = useState(-1);
    const [networkType, setNetworkType] = useState()
    const [web3, setWeb3] = useState()
    const [readonlyWeb3, setReadonlyWeb3] = useState()
    const [language, setLanguage] = useState('en')
    const { t } = useTranslation();
    const [debug, setDebug] = useState();
    const [data, setData] = useState(null);
    const [curRoutes, setCurRoutes] = useState('v2')
    const [connectWalletModalShow, setConnectWalletModalShow] = useState(false)
    const { safe, sdk } = useSafeAppsSDK();
    const [inSafe, setInSafe] = useState(false);
    const [web3Updated, setWeb3Updated] = useState(false);

    const updateWeb3Env = () => {
        setWeb3Updated(!web3Updated);
    };

    useEffect(() => {
        safeAppProvider = new SafeAppProvider(safe, sdk);
    }, [safe, sdk]);

    const loadTokenData = async (web3, networkType, connectedAddress, market) => {
        await Promise.all([
            FetchData.getApyRate(web3, networkType, market)
                .then(response => {
                    market.savingsAPY = response.savingsAPY
                    market.loanAPY = response.loanAPY
                    market.savingsMintAPY = response.savingsMintAPY
                    market.loanMintAPY = response.loanMintAPY
                }),
            FetchData.getPrice(web3, networkType, market).then(response => {
                market.price = response
            })
        ])

        return market
    }

    const loadAllTokenData = async (web3, networkType, account, marketsArr, mainNetWeb3) => {
        if (isLoading) {
            return;
        }

        isLoading = true;

        await FetchData.callContract(web3, account, networkType, mainNetWeb3)
        FetchData.clearMarketDataCache()
        await FetchData.cacheAllMarketData(web3, networkType, account, marketsArr)

        const promises = []
        for (let market of marketsArr) {
            promises.push(loadTokenData(web3, networkType, account, market))
        }

        const dataTree = await Promise.all(promises)
        await Promise.all([
            FetchData.getTotalTVL(marketsArr).then(response => {
                dataTree.totalTVLFiat = response.totalTVLFiat
            })
        ]);
        isLoading = false;
        return dataTree
    }

    useEffect(() => {
        walletTypeChangeRequest.subscribe(async walletKey => {
            // const allDone = await updateWeb3Environment(walletKey);
            // if (allDone) {
            //     setTimeout(() => {
            //         window.location.reload();
            //     }, 1000);
            // }
            await updateWeb3Environment(walletKey);
        })

        // setTimeout(async () => {
        //     updateWeb3Environment(walletType);
        // }, 1500);

        // setWalletType(getActiveWalletType());

        window.localStorage.setItem(globalUtils.COME_FROM, globalUtils.ComeFrom.home);
    }, []);

    useEffect(() => {
        updateWeb3Environment();
        setWalletType(getActiveWalletType());
    }, [web3Updated]);

    const checkNetwork = async () => {
        if (networkType && networkType !== "unsupported" && web3 && readonlyWeb3 && connectedAddress !== globalUtils.NA) {
            if (theTimer) {
                clearInterval(theTimer);
                theTimer = null;
            }

            const loadData = async () => {
                const marketsArr = FetchData.getNetworkMarkets(networkType)
                const result = await loadAllTokenData(
                    readonlyWeb3,
                    networkType,
                    connectedAddress || globalUtils.ADDRESS_PLACEHOLDER,
                    marketsArr,
                    web3
                );

                setData(result);
            };

            loadData();
            theTimer = setInterval(() => {
                loadData();
            }, 15000);
        } else {
            setData(null)
        }
    }

    useEffect(() => {
        checkNetwork();
    }, [networkType, connectedAddress, web3, readonlyWeb3]);


    const handleUpdate = () => {
        checkNetwork()
    }

    const requestAccount = async () => {
        window.ethereum.request({
            method: 'wallet_requestPermissions',
            params: [{ eth_accounts: {} }],
        }).then(permissions => {
            const accountsPermission = permissions.find(
                (permission) => permission.parentCapability === 'eth_accounts'
            );

            if (accountsPermission) {
                console.log('eth_accounts permission successfully requested!');
            }
        }).catch((error) => {
            if (error.code === 4001) {
                // EIP-1193 userRejectedRequest error
                console.warn('Permissions needed to continue.');
            } else {
                console.error(error);
            }
        });
    };

    const handleAccountsChanged = (accounts, web3) => {
        if (specifiedAccount && specifiedAccount?.toLowerCase() != accounts[0]?.toLowerCase()) {
            return requestAccount();
        }

        if (!accounts || accounts.length === 0) {
            setActiveWalletType(null);
            setWalletType(null);
            setConnectedAddress("");
            previousAccount = "";
        } else {
            // if (!previousAccount || previousAccount === accounts[0])
            let account
            if (web3) {
                account = web3.utils.toChecksumAddress(accounts[0])
            }
            setConnectedAddress(account);
            previousAccount = account;
        }
        // else {
        //     window.location.reload();
        // }

        if (accounts && accounts.length > 0) {
            window.localStorage.setItem(globalUtils.ADDRESS_CONNECTED, accounts[0]);
        }
    }

    const handleNetworkChanged = (networkId, walletType = "metamask") => {
        const networkID = parseInt(networkId);
        const networkType = Config.chainIdMap[parseInt(networkID)] // parseInt() because the network sometimes comes as base-10, sometimes hex
        if (networkType) {
            isLoading = false;
            setChainID(networkID);
            setNetworkType(networkType)
            setCurRoutes('v2')
            setActiveWalletType(walletType)
            setWalletType(walletType)
        } else {
            setNetworkType("unsupported")
            setCurRoutes(prevRoute => prevRoute || 'v2')
            setActiveWalletType(null)
            setWalletType(null)
        }
    }

    /**
     * 
     * @param {Object} web3 Web3.
     * @param {Object} provider Provider.
     * @returns {Boolean} Success.
     */
    const checkProviderChanges = async (web3, provider) => {
        const walletConnectingStatus = window.localStorage.getItem(globalUtils.WALLET_CONNECTING);

        try {
            let accounts = [];
            let networkId = 0;

            if (walletConnectingStatus === globalUtils.ConnectStatus.DISCONNECTED || provider.fdWalletType === globalUtils.ConnectStatus.NO_WALLET) {
                networkId = defaultChain.chainId;
            } else {
                networkId = await provider.request({ method: 'eth_chainId' });

                if (provider.fdWalletType !== "walletconnect" && window.localStorage.getItem(globalUtils.ADDRESS_CONNECTED) === "") {
                    try {
                        await provider.request({
                            method: "wallet_requestPermissions",
                            params: [{ eth_accounts: {} }]
                        });
                    } catch (error) {
                        if (error.code !== 4001) {
                            try {
                                if (provider.inSafe) {
                                    await provider.request({ method: "eth_accounts" });
                                } else {
                                    await provider.request({ method: "eth_requestAccounts" });
                                }
                            } catch (error1) {
                                window.localStorage.setItem(globalUtils.WALLET_CONNECTING, globalUtils.ConnectStatus.DISCONNECTED);
                                console.error(error1);
                                return false;
                            }
                        } else {
                            window.localStorage.setItem(globalUtils.WALLET_CONNECTING, globalUtils.ConnectStatus.DISCONNECTED);
                            console.error(error);
                            return false;
                        }
                    }
                }
                accounts = await provider.request({ method: 'eth_accounts' });

                let viewAccount = window.location.hash.substr(1)
                if (web3.utils.isAddress(viewAccount)) {
                    accounts[0] = viewAccount
                }
            }

            handleNetworkChanged(Number(networkId))
            handleAccountsChanged(accounts, web3)
            return true;
        } catch (err) {
            console.error(err);
            const accounts = await provider.enable()
            handleAccountsChanged(accounts, web3)
            return false;
        }
    }

    const connectWallet = async forcedWalletType => {
        const walletConnectingStatus = window.localStorage.getItem(globalUtils.WALLET_CONNECTING);

        try {
            let walletType = forcedWalletType || getActiveWalletType();

            if (forcedWalletType === "unstoppabledomains") {
                try {
                    await globalUtils.getUDAuth().loginWithPopup();
                    const user = await globalUtils.getUDAuth().user();
                    specifiedAccount = user.wallet_address;

                    if (user?.wallet_type_hint === "web3") {
                        walletType = "metamask";
                    } else {
                        walletType = "walletconnect";
                    }
                } catch (error) {
                    return console.error(error);
                }
            }

            let provider, newWeb3;
            if (!walletType || walletType !== "walletconnect") {
                if (safeAppProvider && (window.frames.length != window.parent.frames.length)) {
                    provider = safeAppProvider;
                    provider.inSafe = true;
                    setInSafe(true);
                } else {
                    provider = await globalUtils.getProvider(walletType);
                }

                newWeb3 = new Web3(provider);
                // if (provider && walletConnectingStatus != globalUtils.ConnectStatus.DISCONNECTED) {
                //     provider.fdWalletType = "metamask"
                //     newWeb3 = new Web3(provider);
                // } else {
                //     newWeb3 = new Web3(Config.rpcUrls[defaultChain.chainId]);
                //     provider = { fdWalletType: globalUtils.ConnectStatus.NO_WALLET };
                // }
            } else if (walletType === "walletconnect") {
                provider = await createWalletConnectWeb3Provider();
                if (provider) {
                    provider.fdWalletType = "walletconnect"
                    newWeb3 = new Web3(provider);

                    // provider.on("disconnect", (code, reason) => {
                    //     provider.fdWalletType = globalUtils.ConnectStatus.NO_WALLET;
                    //     return checkProviderChanges(web3, provider);
                    // });

                    // Small hack recommended by the wallet connect team
                    // to force custom client meta instead of html page title/description
                    provider.connector._clientMeta = Config.walletConnect.clientMeta;
                }
            } else {
                setActiveWalletType(null);
                setWalletType(null);
            }

            if (provider) {
                setWeb3(newWeb3);
                let readonlyWeb3 = new Web3(newWeb3.currentProvider)
                setReadonlyWeb3(readonlyWeb3)

                if (provider.fdWalletType !== globalUtils.ConnectStatus.NO_WALLET) {
                    provider.on('accountsChanged', (account) => handleAccountsChanged(account, newWeb3));
                    provider.on('chainChanged', (networkId) => {
                        handleNetworkChanged(networkId, readonlyWeb3.currentProvider.fdWalletType)
                    })
                }

                if (walletType === "metamask" || walletType === "kucoinWallet") {
                    if (provider.inSafe) {
                        await provider.request({ method: "eth_accounts" });
                    } else {
                        await provider.request({ method: "eth_requestAccounts" });
                    }
                } else {
                    await provider.enable();
                }

                const allDone = await checkProviderChanges(newWeb3, provider);

                setActiveWalletType(walletType);
                setWalletType(walletType);

                setGtag(provider, walletConnectingStatus, walletType)

                return allDone;
            } else {
                handleNotConnected()
                return false;
            }

        } catch (e) {
            if (e.message === "User closed modal") {
                console.warn(e.message);
                return false;
            } else {
                throw e;
            }

        }
    };

    const disconnectWallet = async forcedWalletType => {
        const walletTitle = forcedWalletType || getActiveWalletType();

        const killConnecting = () => {
            isLoading = false;
            handleNotConnected(defaultChain.chainId);
            window.localStorage.setItem(globalUtils.ADDRESS_CONNECTED, "");
            window.localStorage.setItem(globalUtils.WALLET_CONNECTING, globalUtils.ConnectStatus.IDLE);
        };

        if (!walletTitle || (walletTitle === "metamask" || walletTitle === "tokenPocket" || walletTitle === "bitKeep" || walletTitle === "kucoinWallet")) {
            killConnecting();
        }

        if (walletTitle === "walletconnect") {
            setActiveWalletType(null);

            try {
                let provider = web3?.currentProvider;
                if (!provider) {
                    provider = await createWalletConnectWeb3Provider();
                }

                provider.on("disconnect", async (code, reason) => {
                    if (code === 1000) {
                        killConnecting();
                        window.location.reload();
                    }
                });

                // await provider.disconnect();
                await provider.close();
            } catch (error) {
                console.error("on closing...", error);
                killConnecting();

                // 这是一个hack的方法，针对这样的情况：例如使用metamask App进行walletconnect连接后再断开连接可能报“Missing or invalid topic field”，在这种情况下强制清除localStorage里的walletconnect记录以重置连接。
                window.localStorage.removeItem(globalUtils.WALLETCONNECT);
            }
        }
    };

    /**
     * 
     * @param {String} forcedWalletType 
     * @returns {Boolean} Success.
     */
    const updateWeb3Environment = async (forcedWalletType) => {
        const walletConnectingStatus = window.localStorage.getItem(globalUtils.WALLET_CONNECTING);

        if (walletConnectingStatus === globalUtils.ConnectStatus.DISCONNECTED) {
            await disconnectWallet(forcedWalletType);
        } else if (walletConnectingStatus === globalUtils.ConnectStatus.CONNECTED) {
            await connectWallet(forcedWalletType);
        } else {
            handleNotConnected(defaultChain.chainId);
        }
    }


    const setGtag = (provider, walletConnectingStatus, walletType) => {
        if (walletConnectingStatus !== globalUtils.ConnectStatus.DISCONNECTED && provider.fdWalletType !== globalUtils.ConnectStatus.NO_WALLET) {
            const gtagParams = {
                url: window.location.href,
                walletType: walletType || 'metamask',
            }
            window.gtag('event', 'setWalletType', gtagParams)
        }
    }

    const getHecoMainNetWeb3 = (chainId = defaultChain.chainId) => {
        if (!hecoMainNetWeb3) {
            // let provider = new Web3.providers.HttpProvider(Config.rpcUrls[chainId]); // Heco mainnet
            // hecoMainNetWeb3 = new Web3(provider);
            hecoMainNetWeb3 = new Web3(Config.rpcUrls[defaultChain.chainId]);
            hecoMainNetWeb3.currentProvider.fdWalletType = globalUtils.ConnectStatus.NO_WALLET;
        }
        return hecoMainNetWeb3;
    }

    const handleNotConnected = async (chainId = defaultChain.chainId) => {
        handleNetworkChanged(chainId)

        let web3 = getHecoMainNetWeb3();
        setWeb3(web3);
        setReadonlyWeb3(new Web3(web3.currentProvider));

        // handleAccountsChanged(['']);
        // handleAccountsChanged(['0x0000000000000000000000000000000000000000']);
        handleAccountsChanged([globalUtils.NA]);
    }

    const languageRouter = () => {
        return (<Suspense fallback={Loading}>
            <Route exact path="/">
                <Redirect to="/en" />
            </Route>

            <Route path="/:lang">
                <LanguageAdapter curRoutes={curRoutes} data={data} inSafe={inSafe} />
            </Route>
        </Suspense>)
    };

    return (
        <Web3Context.Provider value={{
            chainID,
            web3,
            readonlyWeb3,
            hecoMainNetWeb3: getHecoMainNetWeb3(),
            version: curRoutes,
            inSafe: inSafe,
            updateWeb3Env
        }}>
            <WalletTypeContext.Provider value={{ walletType }}>
                <NetworkTypeContext.Provider value={{ networkType }}>
                    <WalletAddressContext.Provider value={{ connectedAddress }}>
                        <LanguageContext.Provider value={{ language, setLanguage }}>
                            <WalletModalContext.Provider value={{ connectWalletModalShow, setConnectWalletModalShow }}>
                                <DataContext.Provider value={{ handleUpdate }}>
                                    <BrowserRouter>{languageRouter()}</BrowserRouter>
                                </DataContext.Provider>
                            </WalletModalContext.Provider>
                        </LanguageContext.Provider>
                    </WalletAddressContext.Provider>
                </NetworkTypeContext.Provider>
            </WalletTypeContext.Provider>
        </Web3Context.Provider>
    )
}

export default App
