import Config from '../utils/config'
import CoreData from './CoreData'
import { BigNumber } from 'bignumber.js'
import BN from 'bn.js'
import { Multicall } from 'ethereum-multicall';
import config from '../utils/config';
import contractABI from '../utils/contractABI.json';
import { globalUtils } from '../utils/globalUtils';
const FlashLoanTool = require('../lib/FlashLoan.json')
// const HecoPool = require('../lib/HecoPool.json')

const multiCaller = (networkType, web3) => {
    return new Multicall({
        multicallCustomContractAddress: Config.multiCall.network[networkType].address,
        web3Instance: web3
    });
}

const ContractCallResult = {}
const callContract = async (web3, connectedAddress, networkType, mainNetWeb3) => {
    const multiCallConfig = Config.multiCall.network[networkType];

    if (!multiCallConfig) {
        console.error("Config.multiCall.network[networkType] =", Config.multiCall.network[networkType]);
        return null;
    }

    const multicall = new Multicall({
        multicallCustomContractAddress: multiCallConfig.address,
        web3Instance: web3
    });
    let marketsArr = getNetworkMarkets(networkType)
    let contractCallContext = []
    let comtrollerCalls = []
    let compoundLensCalls = []

    let gauges = [];
    let gaugeABI = null;
    if (config.veToken[networkType]) {
        gauges = Object.values(config.veToken[networkType].gauge.vault);
        gaugeABI = await globalUtils.loadABI(config.veToken[networkType].gauge.ABI);
    }
    const gaugeControllerCalls = []

    for (let market of marketsArr) {
        const theGauge = gauges.find(gauge => market.network && gauge.underlying.address === market.network[networkType].address);
        if (theGauge) {
            contractCallContext.push({
                reference: market.symbol + ".gauge",
                contractAddress: theGauge.address,
                abi: gaugeABI,
                calls: [
                    { reference: market.symbol + '.gauge.workingBalances', methodName: 'working_balances', methodParameters: [connectedAddress] },
                    { reference: market.symbol + ".gauge.workingSupply", methodName: "working_supply" },
                    { reference: market.symbol + ".gauge.rewardRate", methodName: "reward_rate", methodParameters: [theGauge.underlying.address] }
                ]
            });

            gaugeControllerCalls.push({
                reference: market.symbol + '.gaugeController.gaugeRelativeWeightWrite',
                methodName: 'gauge_relative_weight_write',
                methodParameters: [theGauge.address]
            });
        }

        let marketFTokenCallContext = {
            reference: market.symbol + ".fToken",
            contractAddress: market.qToken.network[networkType].address,
            abi: market.qToken.ABI,
            calls: [
                { reference: market.symbol + '.fToken.supplyRatePerBlock', methodName: 'supplyRatePerBlock' },
                { reference: market.symbol + '.fToken.borrowRatePerBlock', methodName: 'borrowRatePerBlock' },
                { reference: market.symbol + '.fToken.getCash', methodName: 'getCash' },
                { reference: market.symbol + '.fToken.totalReserves', methodName: 'totalReserves' },
                { reference: market.symbol + '.fToken.totalBorrows', methodName: 'totalBorrows' },
                { reference: market.symbol + '.fToken.totalBorrowsCurrent', methodName: 'totalBorrowsCurrent' },
                { reference: market.symbol + '.fToken.balanceOfUnderlying.' + connectedAddress, methodName: 'balanceOfUnderlying', methodParameters: [connectedAddress] },
                { reference: market.symbol + '.fToken.balanceOf.' + connectedAddress, methodName: 'balanceOf', methodParameters: [connectedAddress] },
                { reference: market.symbol + '.fToken.borrowBalanceCurrent.' + connectedAddress, methodName: 'borrowBalanceCurrent', methodParameters: [connectedAddress] },
                { reference: market.symbol + '.fToken.exchangeRateCurrent', methodName: 'exchangeRateCurrent' },
                { reference: market.symbol + '.fToken.interestRateModel', methodName: 'interestRateModel' },
                { reference: market.symbol + '.fToken.reserveFactorMantissa', methodName: 'reserveFactorMantissa' },
                { reference: market.symbol + '.fToken.totalSupply', methodName: 'totalSupply' }
            ]
        }

        if (networkType !== Config.useChainIdMap["128"]) {
            let maticQsConfigCallContext = {
                reference: market.symbol + ".QsConfig",
                contractAddress: Config.qsConfigAddress[networkType],
                abi: Config.QsConfigABI,
                calls: [
                    { reference: market.symbol + '.QsConfig.getBorrowCap.' + connectedAddress, methodName: 'getBorrowCap', methodParameters: [market.qToken.network[networkType].address] },
                    { reference: market.symbol + '.QsConfig.getSupplyCap.' + connectedAddress, methodName: 'getSupplyCap', methodParameters: [market.qToken.network[networkType].address] },
                ]
            }
            contractCallContext.push(maticQsConfigCallContext)
        }

        if (!CoreData.isNativeToken(market.symbol, networkType)) {
            let marketTokenCallContext = {
                reference: market.symbol,
                contractAddress: market.network[networkType].address,
                abi: market.ABI,
                calls: [
                    { reference: market.symbol + '.balanceOf.' + connectedAddress, methodName: 'balanceOf', methodParameters: [connectedAddress] },
                    { reference: market.symbol + '.decimals', methodName: 'decimals' },
                ]
            }
            contractCallContext.push(marketTokenCallContext)

            let erc20CallContext = {
                reference: market.symbol + ".erc20",
                contractAddress: market.network[networkType].address,
                abi: Config.erc20.ABI,
                calls: [
                    { reference: market.symbol + '.erc20.allowance.' + connectedAddress, methodName: 'allowance', methodParameters: [connectedAddress, market.qToken.network[networkType].address] }
                ]
            }
            if (Config.SwapRepayContract && Config.SwapRepayContract[networkType]) {
                erc20CallContext.calls.push({ reference: market.symbol + '.swaprepay.erc20.allowance.' + connectedAddress, methodName: 'allowance', methodParameters: [connectedAddress, Config.SwapRepayContract[networkType]] })
            }

            const LiquidateContractAddress = Config.LiquidateContract[networkType] || market.qToken.network[networkType].address
            if (LiquidateContractAddress) {
                erc20CallContext.calls.push({ reference: market.symbol + '.liquidate.erc20.allowance.' + connectedAddress, methodName: 'allowance', methodParameters: [connectedAddress, LiquidateContractAddress] })
            }
            contractCallContext.push(erc20CallContext)

        }

        let priceOracleCallContext = {
            reference: market.symbol + ".priceOracle",
            contractAddress: Config.priceOracle.network[networkType].address,
            abi: Config.priceOracle.ABI,
            calls: [
                { reference: market.symbol + '.priceOracle.getUnderlyingPrice', methodName: 'getUnderlyingPrice', methodParameters: [market.qToken.network[networkType].address] },
                // { reference: market.symbol + '.priceOracle.getSourcePrice', methodName: 'getSourcePrice', methodParameters: [Config.markets['USDC'].network[networkType].address] }, // USDC as stable coin for zkSyncTest.
            ]
        }

        contractCallContext.push(priceOracleCallContext)
        contractCallContext.push(marketFTokenCallContext)


        comtrollerCalls.push({ reference: market.symbol + '.compSpeeds', methodName: 'compSpeeds', methodParameters: [market.qToken.network[networkType].address] })
        comtrollerCalls.push({ reference: 'comptroller.getAssetsIn.' + connectedAddress, methodName: "getAssetsIn", methodParameters: [connectedAddress] })
        comtrollerCalls.push({ reference: market.symbol + '.checkMembership.' + connectedAddress, methodName: "checkMembership", methodParameters: [connectedAddress, market.qToken.network[networkType].address] })
        comtrollerCalls.push({ reference: market.symbol + '.mintGuardianPaused', methodName: "mintGuardianPaused", methodParameters: [market.qToken.network[networkType].address] })
        comtrollerCalls.push({ reference: market.symbol + '.borrowGuardianPaused', methodName: "borrowGuardianPaused", methodParameters: [market.qToken.network[networkType].address] })
        comtrollerCalls.push({ reference: market.symbol + '.closeFactorMantissa', methodName: "closeFactorMantissa" })
        comtrollerCalls.push({ reference: market.symbol + '.liquidationIncentiveMantissa', methodName: "liquidationIncentiveMantissa" })
        comtrollerCalls.push({
            reference: market.symbol + '.markets', methodName: "markets", methodParameters: [market.qToken.network[networkType].address]
        })

        compoundLensCalls.push({
            reference: market.symbol + '.compoundLens.cTokenMetadataExpand',
            methodName: 'cTokenMetadataExpand',
            methodParameters: [market.qToken.network[networkType].address]
        });
    }

    compoundLensCalls.push({
        reference: 'compoundLens.getAccountLimitsExpand.' + connectedAddress,
        methodName: 'getAccountLimitsExpand',
        methodParameters: [Config.comptroller.network[networkType].address, connectedAddress]
    });

    let comtrollerCallContext = {
        reference: "comtroller",
        contractAddress: Config.comptroller.network[networkType].address,
        abi: Config.comptroller.ABI,
        calls: comtrollerCalls
    }

    let compoundLensCallContext = {
        reference: "compoundLens",
        contractAddress: Config.compoundLens.network[networkType].address,
        abi: Config.compoundLens.ABI,
        calls: compoundLensCalls
    }

    contractCallContext.push(comtrollerCallContext)
    contractCallContext.push(compoundLensCallContext)

    if (config.veToken[networkType]) {
        contractCallContext.push({
            reference: "rewardPolicyMaker",
            contractAddress: config.veToken[networkType].rewardPolicyMaker.address,
            abi: await globalUtils.loadABI(config.veToken[networkType].rewardPolicyMaker.ABI),
            calls: [{ reference: 'rewardPolicyMaker.rateAt', methodName: 'rate_at', methodParameters: [parseInt(new Date().getTime() / 1000)] }]
        });

        contractCallContext.push({
            reference: "gaugeController",
            contractAddress: config.veToken[networkType].gaugeController.address,
            abi: await globalUtils.loadABI(config.veToken[networkType].gaugeController.ABI),
            calls: gaugeControllerCalls
        });
    }

    // console.debug("contractCallContext: ", contractCallContext);
    const multicallResult = await multicall.call(contractCallContext)
    // console.debug("multicallResult: ", multicallResult);

    for (const resultItem in multicallResult.results) {
        let callsReturnArray = multicallResult.results[resultItem].callsReturnContext
        for (let callsReturnItem of callsReturnArray) {
            ContractCallResult[callsReturnItem.reference] = callsReturnItem.returnValues
        }
    }

    // if (networkType === Config.chainIdMap["256"]) {
    //     await getFildaPriceInternal(mainNetWeb3, Config.chainIdMap["128"])
    // } else {
    //     await getFildaPriceInternal(web3, networkType)
    // }
    if (networkType === Config.chainIdMap["128"]) {
        await getFildaPriceInternal(mainNetWeb3, Config.chainIdMap["128"])
    }

    // console.log("====== multicallResult ====", multicallResult)
    // console.log("+++++++ ContractCallResult +++++++", ContractCallResult)
}

const getMulticallKey = (market, methodName, connectedAddress) => {
    let marketKey = ""
    if (market) {
        marketKey = market.symbol + "."
    }
    if (connectedAddress) {
        return marketKey + methodName + "." + connectedAddress
    } else {
        return marketKey + methodName
    }
}

const getWalletBalance = async (web3, connectedAddress, networkType, market) => {
    if (CoreData.isNativeToken(market.symbol, networkType)) {
        const walletBalance = await web3.eth.getBalance(connectedAddress.toString());
        const bnWalletBalance = new BN(walletBalance.toString())
        const walletBalanceFormatted = web3.utils.fromWei(bnWalletBalance, 'ether')
        const walletBalanceFiat = await getFiatValue(web3, networkType, walletBalanceFormatted, market.symbol)
        return {
            walletBalance: walletBalance,
            walletBalanceFormatted: walletBalanceFormatted,
            walletBalanceFiat: walletBalanceFiat
        }
    }
    else {
        const multicallKey = getMulticallKey(market, "balanceOf", connectedAddress)
        const walletBalance = new BigNumber(ContractCallResult[multicallKey][0].hex).toString()

        const decimals = await getDecimals(web3, networkType, market);
        const walletBalanceFormatted = new BigNumber(walletBalance).shiftedBy(-parseInt(decimals)).toString()
        const walletBalanceFiat = await getFiatValue(web3, networkType, walletBalanceFormatted, market.symbol)

        return {
            walletBalance: walletBalance,
            walletBalanceFormatted: walletBalanceFormatted,
            walletBalanceFiat: walletBalanceFiat
        }
    }
}

const getPriceViaChainlink = async (web3, networkType, tokenAddress) => {
    const priceOracleContract = new web3.eth.Contract(Config.priceOracle.ABI, Config.priceOracle.network[networkType].address);
    const priceBundle = await priceOracleContract.methods.getSourcePrice(tokenAddress).call();
    return priceBundle?.answer || 0;
};

const getLPAPY = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getLPAPY`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }

    const lpPrice = await getPriceViaChainlink(web3, networkType, market.network[networkType].address);
    const mojitoPrice = await getPriceViaChainlink(web3, networkType, market.lpRewardsTokens[0].network[networkType].address);

    let priceB = 0;
    if (market.lpRewardsTokens.length > 1) {
        const priceBPrice = await getPriceViaChainlink(web3, networkType, market.lpRewardsTokens[1].network[networkType].address);
        priceB = new BigNumber(priceBPrice).shiftedBy(globalUtils.DEFAULT_MAX_DECIMALS - market.lpRewardsTokens[1].decimals).integerValue().toFixed();
    }

    const contract = new web3.eth.Contract(Config.compoundLens.ABI, Config.compoundLens.network[networkType].address);
    const result = await contract.methods.getTMLPDIR(
        market.qToken.network[networkType].address,
        new BigNumber(mojitoPrice).shiftedBy(globalUtils.DEFAULT_MAX_DECIMALS - market.lpRewardsTokens[0].decimals).integerValue().toFixed(),
        priceB,
        new BigNumber(lpPrice).shiftedBy(globalUtils.DEFAULT_MAX_DECIMALS - market.network[networkType].decimals).integerValue().toFixed()
    ).call();
    const apyA = 365 * Number(result.apyA) / 1e18;
    const apyB = 365 * Number(result.apyB) / 1e18;
    const apy = (apyA + apyB) * 100;

    MarketDataCache[key] = apy;

    return apy;
};

const getGaugeAPY = async (web3, networkType, market) => {
    if (!Config.veToken[networkType]) {
        return 0;
    }

    const key = `${networkType}|${market.symbol}|getGaugeAPY`;
    const gauge = Config.veToken[networkType].gauge.vault[market.symbol];

    if (MarketDataCache[key]) {
        return MarketDataCache[key];
    }

    // let resultBundle = ContractCallResult[getMulticallKey(market, "gauge.workingBalances")];
    // let workingBalances;
    // if (resultBundle) {
    //     workingBalances = new BigNumber(resultBundle[0].hex);
    // } else {
    //     return 0;
    // }

    // resultBundle = ContractCallResult[getMulticallKey(market, "gauge.workingSupply")];
    // let workingSupply;
    // if (resultBundle) {
    //     workingSupply = new BigNumber(resultBundle[0].hex);
    // } else {
    //     return 0;
    // }

    let resultBundle = ContractCallResult[getMulticallKey(market, "gauge.rewardRate")];
    let rewardRate;
    if (resultBundle) {
        rewardRate = new BigNumber(resultBundle[0].hex);
    } else {
        return 0;
    }

    resultBundle = ContractCallResult[getMulticallKey(null, "rewardPolicyMaker.rateAt")];
    let rateAt;
    if (resultBundle) {
        rateAt = new BigNumber(resultBundle[0].hex);
    } else {
        return 0;
    }

    // resultBundle = ContractCallResult[getMulticallKey(market, "gaugeController.gaugeRelativeWeightWrite")];
    // let gaugeRelativeWeightWrite;
    // if (resultBundle) {
    //     gaugeRelativeWeightWrite = new BigNumber(resultBundle[0].hex);
    // } else {
    //     return 0;
    // }

    const priceOracleContract = new web3.eth.Contract(Config.priceOracle.ABI, Config.priceOracle.network[networkType].address);

    const mojitoPrice = await priceOracleContract.methods.getSourcePrice(Config.veToken[networkType].rewards.address).call();
    const mjtPrice = mojitoPrice.answer / 1e8;

    const torPrice = await getFildaPrice(web3, networkType);

    const tokenPrice = market.price;

    const totalSupplyFiat = market.totalSupplyFiat;

    const secondsYear = 31536000;

    const apr = rewardRate.shiftedBy(-gauge.underlying.decimals)
        .multipliedBy(mjtPrice)
        .plus(rateAt.shiftedBy(-18))
        .multipliedBy(torPrice)
        .dividedBy(totalSupplyFiat * tokenPrice)
        .multipliedBy(secondsYear);

    MarketDataCache[key] = apr;

    return apr;
};

const getApyRate = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getApyRate`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }
    const ethMantissa = 1e18;
    const blockInterval = Config?.blockInterval?.[networkType] || 3
    const blocksPerMinute = 60 / blockInterval
    const blocksPerDay = blocksPerMinute * 60 * 24
    const daysPerYear = 365;

    const supplyRatePerBlock = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.supplyRatePerBlock")][0].hex).toString(10)

    // const supplyApy = (((Math.pow((supplyRatePerBlock / ethMantissa * blocksPerDay) + 1, daysPerYear - 1))) - 1) * 100; // filda中的计算方法, 差一天的利息
    let supplyApy = 0;
    if (market.isLPToken) {
        supplyApy = await getLPAPY(web3, networkType, market);
    } else {
        supplyApy = (((Math.pow((supplyRatePerBlock / ethMantissa * blocksPerDay) + 1, daysPerYear))) - 1) * 100
    }

    const borrowRatePerBlock = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.borrowRatePerBlock")][0].hex).toString(10)
    const borrowApy = (((Math.pow((borrowRatePerBlock / ethMantissa * blocksPerDay) + 1, daysPerYear))) - 1) * 100;
    // const borrowApy = market.isLPToken ? 0 : (((Math.pow((borrowRatePerBlock / ethMantissa * blocksPerDay) + 1, daysPerYear - 1))) - 1) * 100; // filda中的计算方法, 差一天的利息

    const compSpeedPerBlock = new BigNumber(ContractCallResult[getMulticallKey(market, "compSpeeds")][0].hex).toString(10)
    let compSupplySpeedPerBlock = compSpeedPerBlock / Math.pow(2, 128)
    let compBorrowSpeedPerBlock = BigNumber(compSpeedPerBlock).mod(BigNumber(2).pow(128)).toString(10)
    if (compSupplySpeedPerBlock <= 1) {
        compSupplySpeedPerBlock = compBorrowSpeedPerBlock
    }
    const compSpeedPerDay = BigNumber(compSupplySpeedPerBlock).plus(BigNumber(compBorrowSpeedPerBlock)).multipliedBy(blocksPerDay).toString(10)
    const compSupplySpeedPerDay = BigNumber(compSupplySpeedPerBlock).multipliedBy(blocksPerDay).toString(10)
    const compBorrowSpeedPerDay = BigNumber(compBorrowSpeedPerBlock).multipliedBy(blocksPerDay).toString(10)

    const fildaPrice = await getFildaPrice(web3, networkType)
    const fildaSpeedAPY = compSpeedPerDay * daysPerYear / ethMantissa
    const fildaSpeedFiatAPY = fildaPrice * fildaSpeedAPY

    const totalBorrowed = await getTotalBorrowed(web3, networkType, market)

    const fildaPriceConst = 0.01

    let borrowMintApy, borrowMonthlyRewards
    if (market.isLPToken || totalBorrowed.totalBorrowedFiat === 0) {
        borrowMintApy = 0
        borrowMonthlyRewards = 0
    } else {
        borrowMintApy = compBorrowSpeedPerDay * fildaPriceConst * daysPerYear / totalBorrowed.totalBorrowedFiat / Math.pow(10, 16)
        borrowMonthlyRewards = (10000 / (10000 + totalBorrowed.totalBorrowedFiat)) * (compBorrowSpeedPerDay / 1e18) * 30;

    }

    const totalSupply = await getTotalSupply(web3, networkType, market)

    let supplyMintApy, supplyMonthlyRewards
    if (totalSupply.totalSupplyFiat === 0) {
        supplyMintApy = 0
        supplyMonthlyRewards = 0
    } else {
        supplyMintApy = compSupplySpeedPerDay * fildaPriceConst * daysPerYear / totalSupply.totalSupplyFiat / Math.pow(10, 16)
        supplyMonthlyRewards = (10000 / (10000 + totalSupply.totalSupplyFiat)) * (compSupplySpeedPerDay / 1e18) * 30;
    }

    return {
        savingsAPY: supplyApy,
        loanAPY: borrowApy,
        savingsMintAPY: supplyMintApy,
        loanMintAPY: borrowMintApy,
        fildaSpeedAPY,
        fildaSpeedFiatAPY,
        compSupplySpeedPerDay: compSupplySpeedPerDay / Math.pow(10, 18), // 存款代币奖励数量
        compBorrowSpeedPerDay: compBorrowSpeedPerDay / Math.pow(10, 18), // 借款代币奖励数量
        borrowMonthlyRewards, // 存款30天1万u的奖励
        supplyMonthlyRewards, // 借款30天1万u的奖励
    }
}

const getMarketPercentage = async (web3, networkType, market) => {
    let marketsArr = getNetworkMarkets(networkType)

    const compSpeedPerBlock = new BigNumber(ContractCallResult[getMulticallKey(market, "compSpeeds")][0].hex).toString(10)
    const totalBorrowed = await getTotalBorrowed(web3, networkType, market)

    let totalCompSpeed = new BigNumber(0);
    let totalBorrowedFiat = new BigNumber(0);
    for (let market of marketsArr) {
        totalCompSpeed = totalCompSpeed.plus(ContractCallResult[getMulticallKey(market, "compSpeeds")][0].hex)
        const totalBorrowed = await getTotalBorrowed(web3, networkType, market)
        totalBorrowedFiat = totalBorrowedFiat.plus(totalBorrowed.totalBorrowedFiat)
    }

    let percentageOfTotalBorrowed
    if (totalBorrowedFiat.comparedTo(0) === 0) {
        percentageOfTotalBorrowed = "0"
    } else {
        percentageOfTotalBorrowed = new BigNumber(totalBorrowed.totalBorrowedFiat).dividedBy(totalBorrowedFiat).toString(10)
    }

    let percentageOfTotalMining
    if (totalCompSpeed.comparedTo(0) === 0) {
        percentageOfTotalMining = "0"
    } else {
        percentageOfTotalMining = new BigNumber(compSpeedPerBlock).dividedBy(totalCompSpeed).toString(10)
    }

    return {
        percentageOfTotalBorrowed,
        percentageOfTotalMining
    }
}

const getFildaPriceInternal = async (web3, networkType) => {
    let reserves = ContractCallResult["uniswap.getReserves." + networkType]
    if (!reserves) {
        const uniswapContractABI = Config.uniswapPair.ABI
        const uniswapContractAddress = Config.uniswapPair.network[networkType].filda.address
        const uniswapContract = new web3.eth.Contract(uniswapContractABI, uniswapContractAddress)
        reserves = await uniswapContract.methods.getReserves().call()
        ContractCallResult["uniswap.getReserves." + networkType] = reserves
    }
    return reserves
}

const getFildaPrice = async (web3, networkType) => {
    if (!Config.uniswapPair.network[networkType]) {
        return 0
    }
    const fildaPrice = await getTokenPriceInUSD(web3, networkType, "filda")
    return fildaPrice
}

const getTokenPriceInternal = async (web3, networkType, token) => {
    let reserves = ContractCallResult["uniswap.getReserves." + networkType + "." + token]
    if (!reserves) {
        const uniswapContractABI = Config.uniswapPair.ABI
        const uniswapContractAddress = Config.uniswapPair.network[networkType][token].address
        const uniswapContract = new web3.eth.Contract(uniswapContractABI, uniswapContractAddress)
        reserves = await uniswapContract.methods.getReserves().call()
        ContractCallResult["uniswap.getReserves." + networkType + "." + token] = reserves
    }
    return reserves
}
const getTokenPriceInUSD = async (web3, networkType, token) => {
    if (!Config.uniswapPair.network[networkType] || !Config.uniswapPair.network[networkType][token]) {
        return 0
    }
    const reserves = await getTokenPriceInternal(web3, networkType, token)
    const stablecoinReserve = reserves[Config.uniswapPair.network[networkType][token].stablecoin]
    const tokenReserve = reserves[Config.uniswapPair.network[networkType][token].token]

    let tokenInStableCoin = stablecoinReserve * Math.pow(10, Config.uniswapPair.network[networkType][token].shift) / tokenReserve
    if (Config.uniswapPair.network[networkType][token].middle) {
        let middlePriceInUSD = await getTokenPriceInUSD(web3, networkType, Config.uniswapPair.network[networkType][token].middle)
        tokenInStableCoin = tokenInStableCoin * middlePriceInUSD
    }
    return tokenInStableCoin
}

/**
 * pair: token <=> usdt.
 * @param {Object} web3
 * @param {Object} abiOfPair ABI.json of the uniswap pair contract.
 * @param {String}} addressOfPair
 * @returns the token price in $.
 */
const getTokenUSDPriceViaSwapPair = async (web3, abiOfPair, addressOfPair, decimalsOfToken, decimalsOfUSDT, reverse) => {
    const uniswapContract = new web3.eth.Contract(abiOfPair, addressOfPair);
    const reserves = await uniswapContract.methods.getReserves().call();
    const tokenReserve = reserves._reserve0 || reserves.reserve0;
    const usdtReserve = reserves._reserve1 || reserves.reserve1;
    if (!reverse) {
        return new BigNumber(usdtReserve)
            .shiftedBy(-decimalsOfUSDT)
            .dividedBy(new BigNumber(tokenReserve).shiftedBy(-decimalsOfToken));
    } else {
        return new BigNumber(tokenReserve)
            .shiftedBy(-decimalsOfToken)
            .dividedBy(new BigNumber(usdtReserve).shiftedBy(-decimalsOfUSDT));
    }
}

const getDogPrice = async (web3, fildaPrice) => {
    const uniswapContractABI = Config.mdex.hecoPoolPair
    const uniswapContractAddress = "0xBd0d0482B6a6c1783857fb6B9Db02932A100Ee10"
    const uniswapContract = new web3.eth.Contract(uniswapContractABI, uniswapContractAddress)
    const reserves = await uniswapContract.methods.getReserves().call()
    const dogReserve = reserves._reserve0
    const fildaReserve = reserves._reserve1
    return new BigNumber(fildaReserve).multipliedBy(fildaPrice).dividedBy(dogReserve)
}

const getPriceInFilDA = async (web3, lpPairAddress) => {
    const uniswapContractABI = Config.mdex.hecoPoolPair
    const uniswapContract = new web3.eth.Contract(uniswapContractABI, lpPairAddress)
    const reserves = await uniswapContract.methods.getReserves().call()
    const pairTokenReserve = reserves._reserve0
    const fildaReserve = reserves._reserve1

    let priceInFilDA = new BigNumber(fildaReserve).multipliedBy(Math.pow(10, 18)).div(pairTokenReserve);
    return priceInFilDA
}

const getLiquidityBalance = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getLiquidityBalance`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }
    const price = await getPrice(web3, networkType, market);
    const liquidity = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.getCash")][0].hex).toString(10)
    const totalReserves = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.totalReserves")][0].hex).toString(10)
    const bnLiquidity = new BN(liquidity)
    if (CoreData.isNativeToken(market.symbol, networkType)) {
        const liquidityFormatted = web3.utils.fromWei(bnLiquidity, 'ether');
        const liquidityFiat = liquidityFormatted * price
        return {
            liquidity: liquidity,
            liquidityFormatted: liquidityFormatted,
            liquidityFiat: liquidityFiat,
            totalReserves
        }
    } else {
        const decimals = await getDecimals(web3, networkType, market);
        const liquidityFormatted = (liquidity / Math.pow(10, parseInt(decimals)))
        const liquidityFiat = liquidityFormatted * price
        return {
            liquidity: liquidity,
            liquidityFormatted: liquidityFormatted,
            liquidityFiat: liquidityFiat,
            totalReserves
        }
    }
}


const getUSDTInUSD = async (web3, networkType) => {
    const key = `${networkType}|getUSDTInUSD`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }
    const usd = +new BigNumber(ContractCallResult[getMulticallKey(Config.markets['USDT'], "priceOracle.getSourcePrice")][1].hex).shiftedBy(-8)  // 获取 usdt 的usd价格
    return usd
}

const getPriceInUSD = async (web3, networkType, market) => {
    let withUSDT = false
    var usdtMarket
    var usdtDecimals = 18
    switch (networkType) {
        case Config.chainIdMap["280"]:
            usdtMarket = Config.markets['USDC']
            usdtDecimals = 6
            break;

        case Config.chainIdMap["322"]:
        case Config.chainIdMap["321"]:
        default:
            usdtMarket = Config.markets['USDT']
            withUSDT = true
            break
    }

    const marketPriceInETH = new BigNumber(ContractCallResult[getMulticallKey(market, "priceOracle.getUnderlyingPrice")][0]?.hex || 0);

    var marketDecimals = 18
    if (market.ABI) {
        marketDecimals = await getDecimals(web3, networkType, market);
    }

    const usdtPriceInETHInBigNumber = new BigNumber(ContractCallResult[getMulticallKey(usdtMarket, "priceOracle.getUnderlyingPrice")][0].hex)
    const usdtPriceInETH = usdtPriceInETHInBigNumber.toString(10)
    const bnUsdtPriceInETH = new BN(usdtPriceInETH)

    if (usdtPriceInETHInBigNumber.comparedTo(0) === 0) {
        return 0
    } else {
        // const priceInUsdt = parseFloat(marketPriceInETH / Math.pow(10, usdtDecimals)) / parseFloat(web3.utils.fromWei(bnUsdtPriceInETH)) / Math.pow(10, parseInt(18 - marketDecimals))
        // const usd = await getUSDTInUSD(web3, networkType)
        // return +BigNumber(priceInUsdt).times(usd)
        return marketPriceInETH.shiftedBy(-usdtDecimals).dividedBy(web3.utils.fromWei(bnUsdtPriceInETH)).shiftedBy(-(18 - marketDecimals)).toNumber();
    }
}
const MarketDataCache = {}
const clearMarketDataCache = () => {
    for (let key in MarketDataCache) {
        delete MarketDataCache[key];
    }
}
const cacheAllMarketData = async (web3, networkType, connectedAddress, marketsArr) => {
    const promises = [];
    for (let market of marketsArr) {
        promises.push(getPrice(web3, networkType, market).then(response => {
            MarketDataCache[`${networkType}|${market.symbol}|getPrice`] = response;
        }))
        if (!CoreData.isNativeToken(market.symbol, networkType)) {
            promises.push(getDecimals(web3, networkType, market).then(response => {
                MarketDataCache[`${networkType}|${market.symbol}|getDecimals`] = response;
            }))
        }
        promises.push(getReserveFactor(web3, networkType, market).then(response => {
            MarketDataCache[`${networkType}|${market.symbol}|getReserveFactor`] = response;
        }))
        promises.push(getCloseFactor(web3, networkType, market).then(response => {
            MarketDataCache[`${networkType}|${market.symbol}|getCloseFactor`] = response;
        }))
        promises.push(getLoanBalance(web3, connectedAddress, networkType, market).then(response => {
            MarketDataCache[`${networkType}|${market.symbol}|getLoanBalance`] = response;
        }))
        promises.push(getSavingsBalance(web3, connectedAddress, networkType, market).then(response => {
            MarketDataCache[`${networkType}|${market.symbol}|getSavingsBalance`] = response;
        }))
        promises.push(getLiquidityBalance(web3, networkType, market).then(response => {
            MarketDataCache[`${networkType}|${market.symbol}|getLiquidityBalance`] = response;
        }))
        promises.push(getTotalBorrowed(web3, networkType, market).then(response => {
            MarketDataCache[`${networkType}|${market.symbol}|getTotalBorrowed`] = response;
        }))
        promises.push(getExchangeRate(web3, networkType, market).then(response => {
            MarketDataCache[`${networkType}|${market.symbol}|getExchangeRate`] = response;
        }))
        promises.push(getApyRate(web3, networkType, market).then(response => {
            MarketDataCache[`${networkType}|${market.symbol}|getApyRate`] = response;
        }))
    }
    // promises.push(getUSDTInUSD(web3, networkType).then(response => {
    //     MarketDataCache[`${networkType}|getUSDTInUSD`] = response;
    // }))
    await Promise.all(promises);
}

const getPrice = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getPrice`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }

    const priceInUSD = await getPriceInUSD(web3, networkType, market)
    return priceInUSD
}
const getDecimals = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getDecimals`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }
    if (CoreData.isNativeToken(market.symbol, networkType)) {
        return 18;
    }
    const decimals = new BigNumber(ContractCallResult[getMulticallKey(market, "decimals")][0]).toString(10)
    return decimals;
}

const getReserveFactor = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getReserveFactor`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }
    const reserveFactorMantissa = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.reserveFactorMantissa")][0].hex).toString(10)
    return reserveFactorMantissa / 1e18;
}

// 清算比例
const getCloseFactor = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getCloseFactor`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }
    const closeFactorMantissa = new BigNumber(ContractCallResult[getMulticallKey(market, "closeFactorMantissa")][0].hex)
    // const decimals = await getDecimals(web3, networkType, market)
    // const ressult = +closeFactorMantissa.shiftedBy(-parseInt(decimals))
    const ressult = +closeFactorMantissa.shiftedBy(-18)

    return ressult;
}

// 清算罚金
const getLiquidationIncentive = async (web3, networkType, market) => {
    let liquidationIncentive = new BigNumber(ContractCallResult[getMulticallKey(market, "markets")][4].hex)
    // const decimals = parseInt(await getDecimals(web3, networkType, market));
    const decimals = 18;
    if (liquidationIncentive.eq(0)) {
        liquidationIncentive = new BigNumber(ContractCallResult[getMulticallKey(market, "liquidationIncentiveMantissa")][0].hex)
    }

    if (liquidationIncentive.gt(new BigNumber(1).shiftedBy(decimals))) {
        return liquidationIncentive.shiftedBy(-decimals).minus(1).multipliedBy(100);
    } else {
        return BigNumber(0);
    }
}

const getSupplyRewardsAPR = market => {
    return new BigNumber(market.savingsBalance).dividedBy(market.totalSupply).multipliedBy(market.compSupplySpeedPerDay).multipliedBy(365);
};

const getBorrowRewardsAPR = market => {
    return new BigNumber(market.loanBalance).dividedBy(market.totalBorrowed).multipliedBy(market.compBorrowSpeedPerDay).multipliedBy(365);
};

const getSavingsBalance = async (web3, connectedAddress, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getSavingsBalance`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }

    const price = await getPrice(web3, networkType, market);

    const savingsBalance = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.balanceOfUnderlying", connectedAddress)][0].hex).toString(10)
    const savingsCTokenBalance = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.balanceOf", connectedAddress)][0].hex).toString(10)
    const totalCTokenSupply = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.totalSupply")][0].hex).toString(10);

    if (CoreData.isNativeToken(market.symbol, networkType)) {
        const bnSavingsBalance = new BN(savingsBalance)
        const savingsBalanceFormatted = web3.utils.fromWei(bnSavingsBalance, 'ether');
        const savingsBalanceFiat = savingsBalanceFormatted * price
        return {
            savingsBalance: savingsBalance,
            savingsBalanceFormatted: savingsBalanceFormatted,
            savingsBalanceFiat: savingsBalanceFiat,
            savingsCTokenBalance: savingsCTokenBalance,
            totalCTokenSupply
        }
    }
    else {
        const decimals = await getDecimals(web3, networkType, market);
        const savingsBalanceFormatted = (savingsBalance / Math.pow(10, parseInt(decimals)))
        const savingsBalanceFiat = savingsBalanceFormatted * price
        return {
            savingsBalance: savingsBalance,
            savingsBalanceFormatted: savingsBalanceFormatted,
            savingsBalanceFiat: savingsBalanceFiat,
            savingsCTokenBalance: savingsCTokenBalance,
            totalCTokenSupply
        }
    }
}

const getInterestRateModelInternal = async (web3, networkType, marketsArr) => {
    const multicall = new Multicall({
        multicallCustomContractAddress: Config.multiCall.network[networkType].address,
        web3Instance: web3
    });
    let contractCallContext = []
    for (let market of marketsArr) {
        const interestRateModelAddress = ContractCallResult[market.symbol + '.fToken.interestRateModel']
        let interestRateModelContractCallContext = {
            reference: market.symbol + ".fToken.interestRateModel",
            contractAddress: interestRateModelAddress[0],
            abi: Config.interestRateModel.ABI,
            calls: [
                { reference: market.symbol + '.fToken.interestRateModel.blocksPerYear', methodName: 'blocksPerYear' },
                { reference: market.symbol + '.fToken.interestRateModel.baseRatePerBlock', methodName: 'baseRatePerBlock' },
                { reference: market.symbol + '.fToken.interestRateModel.multiplierPerBlock', methodName: 'multiplierPerBlock' },
                { reference: market.symbol + '.fToken.interestRateModel.jumpMultiplierPerBlock', methodName: 'jumpMultiplierPerBlock' },
                { reference: market.symbol + '.fToken.interestRateModel.kink', methodName: 'kink' },

            ]
        }
        contractCallContext.push(interestRateModelContractCallContext)
    }

    let multicallResult = { results: [] }
    try {
        multicallResult = await multicall.call(contractCallContext)
    } catch (error) {
        console.error(error)
    }

    for (const resultItem in multicallResult.results) {
        let callsReturnArray = multicallResult.results[resultItem].callsReturnContext
        for (let callsReturnItem of callsReturnArray) {
            ContractCallResult[callsReturnItem.reference] = callsReturnItem.returnValues
        }
    }
}

const getInterestRateModel = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getInterestRateModel`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }

    await getInterestRateModelInternal(web3, networkType, [market])

    const blocksPerYear = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.interestRateModel.blocksPerYear")][0].hex).toString(10)
    const baseRatePerBlock = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.interestRateModel.baseRatePerBlock")][0].hex).toString(10)
    const jumpMultiplierPerBlock = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.interestRateModel.jumpMultiplierPerBlock")][0].hex).toString(10)
    const multiplierPerBlock = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.interestRateModel.multiplierPerBlock")][0].hex).toString(10)
    const kink = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.interestRateModel.kink")][0].hex).toString(10)

    const response = {
        blocksPerYear: blocksPerYear,
        baseRatePerBlock: baseRatePerBlock,
        jumpMultiplierPerBlock: jumpMultiplierPerBlock,
        multiplierPerBlock: multiplierPerBlock,
        kink: kink,
    }
    MarketDataCache[`${networkType}|${market.symbol}|getInterestRateModel`] = response
    return response
}

const getTotalSavingsBalance = async (web3, connectedAddress, networkType, marketsArr) => {
    let totalFiatBalance = 0;
    await Promise.all(marketsArr.map(async (market) => {
        const marketSavingsBalance = await getSavingsBalance(web3, connectedAddress, networkType, market);
        const marketPrice = await getPrice(web3, networkType, market);
        const marektFiatBalance = marketSavingsBalance.savingsBalanceFormatted * marketPrice;
        totalFiatBalance = totalFiatBalance + marektFiatBalance;
    }));
    return totalFiatBalance;
}

const getFiatValue = async (web3, networkType, tokenValue, tokenSymbol) => {
    var market = Config.markets[tokenSymbol]

    if (tokenSymbol === 'ETH' && networkType === Config.chainIdMap["280"]) {
        market = Config.markets["WETH"];
    }

    const tokenPrice = await getPrice(web3, networkType, market);
    return tokenValue * tokenPrice;
}

const getAccountAllowance = async (web3, connectedAddress, networkType, market) => {
    if (!CoreData.isNativeToken(market.symbol, networkType)) {
        const [allowance, decimals] = await Promise.all([
            new BigNumber(ContractCallResult[getMulticallKey(market, "erc20.allowance", connectedAddress)][0].hex).toString(10),
            getDecimals(web3, networkType, market)
        ])
        const allowanceFormatted = (allowance / Math.pow(10, parseInt(decimals)))
        return { allowance: Number(allowance), allowanceFormatted }
    }
}

const getSwapRepayAllowance = async (web3, connectedAddress, networkType, market) => {
    if (Config.SwapRepayContract && !Config.SwapRepayContract[networkType]) {
        return false
    }
    if (ContractCallResult[getMulticallKey(market, "swaprepay.erc20.allowance", connectedAddress)] && !CoreData.isNativeToken(market.symbol, networkType)) {
        const [allowance, decimals] = await Promise.all([
            new BigNumber(ContractCallResult[getMulticallKey(market, "swaprepay.erc20.allowance", connectedAddress)][0].hex).toString(10),
            getDecimals(web3, networkType, market)
        ])
        const allowanceFormatted = (allowance / Math.pow(10, parseInt(decimals)))
        return { allowance: Number(allowance), allowanceFormatted }
    }
}

const getDepositRepayAllowance = async (web3, connectedAddress, networkType, market) => {
    if (!Config.DepositRepayContract[networkType]) {
        return false
    }
    const [allowance] = await Promise.all([new BigNumber(ContractCallResult[getMulticallKey(market, "deposit.qtoken.allowance", connectedAddress)][0].hex).toString(10)])
    const decimals = 18
    const allowanceFormatted = (allowance / Math.pow(10, parseInt(decimals)))
    return { allowance: Number(allowance), allowanceFormatted }
}

const getDepositSwapAllowance = async (web3, connectedAddress, networkType, market) => {
    if (Config.DepositSwapContract && !Config.DepositSwapContract[networkType]) {
        return false
    }

    if (!ContractCallResult[getMulticallKey(market, "depositswap.qtoken.allowance", connectedAddress)]) {
        return;
    }

    const [allowance] = await Promise.all([new BigNumber(ContractCallResult[getMulticallKey(market, "depositswap.qtoken.allowance", connectedAddress)][0].hex).toString(10)])
    const decimals = 18
    const allowanceFormatted = (allowance / Math.pow(10, parseInt(decimals)))
    return { allowance: Number(allowance), allowanceFormatted }
}

const getLiquidateAllowance = async (web3, connectedAddress, networkType, market) => {
    if ((networkType === 'heco' && !Config.LiquidateContract[networkType]) || (networkType !== 'heco' && !market.qToken.network[networkType].address)) {
        return false
    }

    if (!CoreData.isNativeToken(market.symbol, networkType)) {
        const [allowance, decimals] = await Promise.all([
            new BigNumber(ContractCallResult[getMulticallKey(market, "liquidate.erc20.allowance", connectedAddress)][0].hex).toString(10),
            getDecimals(web3, networkType, market)
        ])
        const allowanceFormatted = (allowance / Math.pow(10, parseInt(decimals)))
        return { allowance: Number(allowance), allowanceFormatted }
    }
}

const getAccountLiquidity = async (web3, connectedAddress, networkType) => {
    const liquidity = new BigNumber(ContractCallResult[getMulticallKey(null, "compoundLens.getAccountLimitsExpand", connectedAddress)][0].hex).toString(10)
    const bnLiquidity = new BN(liquidity)
    const liquidityInFiat = await getFiatValue(web3, networkType, web3.utils.fromWei(bnLiquidity).toString(), 'ETH')

    return {
        "inETH": web3.utils.fromWei(bnLiquidity).toString(),
        "inFiat": liquidityInFiat
    }

}

const getCollateralStatus = async (web3, connectedAddress, networkType, market) => {
    const theNetwork = market.qToken.network[networkType]
    if (!theNetwork) {
        return false
    }

    const marketAddress = theNetwork.address

    let activeCollaterals = ContractCallResult[getMulticallKey(null, "comptroller.getAssetsIn", connectedAddress)]

    let status = false
    await Promise.all(activeCollaterals.map(collateral => {
        if (collateral.toLowerCase() === marketAddress.toLowerCase()) {
            status = true
        }
    }))

    return status
}


const getLoanBalance = async (web3, connectedAddress, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getLoanBalance`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }
    const price = await getPrice(web3, networkType, market);

    const loanBalance = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.borrowBalanceCurrent", connectedAddress)][0].hex).toString(10)
    if (CoreData.isNativeToken(market.symbol, networkType)) {
        const bnLoanBalance = new BN(loanBalance)
        const loanBalanceFormatted = web3.utils.fromWei(bnLoanBalance, 'ether');
        const loanBalanceFiat = loanBalanceFormatted * price
        return {
            loanBalance: loanBalance,
            loanBalanceFormatted: loanBalanceFormatted,
            loanBalanceFiat: loanBalanceFiat
        }
    }
    else {
        const decimals = await getDecimals(web3, networkType, market);
        const loanBalanceFormatted = (loanBalance / Math.pow(10, parseInt(decimals)))
        const loanBalanceFiat = loanBalanceFormatted * price
        return {
            loanBalance: loanBalance,
            loanBalanceFormatted: loanBalanceFormatted,
            loanBalanceFiat: loanBalanceFiat
        }
    }
}

const getTotalLoanBalance = async (web3, connectedAddress, networkType, marketsArr) => {
    let totalBalanceInFiat = 0;
    await Promise.all(marketsArr.map(async (market) => {
        const marketBalance = await getLoanBalance(web3, connectedAddress, networkType, market);
        const marketPrice = await getPrice(web3, networkType, market);
        const marektBalanceInFiat = marketBalance.loanBalanceFormatted * marketPrice;
        totalBalanceInFiat = totalBalanceInFiat + marektBalanceInFiat;
    }));
    return totalBalanceInFiat;
}

const getTotalSavingsAPY = async (web3, connectedAddress, networkType, marketsArr) => {
    let totalSavingsBalanceFiat = 0
    let totalSavingsInterest = 0
    await Promise.all(marketsArr.map(async (market) => {
        const savingsBalance = await getSavingsBalance(web3, connectedAddress, networkType, market)
        const apy = await getApyRate(web3, networkType, market)
        if (savingsBalance.savingsBalance > 0) {
            totalSavingsBalanceFiat = totalSavingsBalanceFiat + savingsBalance.savingsBalanceFiat
            totalSavingsInterest = totalSavingsInterest + savingsBalance.savingsBalanceFiat * apy.savingsAPY / 100
        }
    }))
    return (totalSavingsInterest / totalSavingsBalanceFiat) * 100
}

const getTotalLoanAPY = async (web3, connectedAddress, networkType, marketsArr) => {
    let totalLoanBalanceFiat = 0
    let totalLoanInterest = 0
    await Promise.all(marketsArr.map(async (market) => {
        const loanBalance = await getLoanBalance(web3, connectedAddress, networkType, market)
        const apy = await getApyRate(web3, networkType, market)
        if (loanBalance.loanBalance > 0) {
            totalLoanBalanceFiat = totalLoanBalanceFiat + loanBalance.loanBalanceFiat
            totalLoanInterest = totalLoanInterest + loanBalance.loanBalanceFiat * apy.loanAPY / 100
        }
    }))
    return (totalLoanInterest / totalLoanBalanceFiat) * 100
}

const getNetAPY = async (web3, connectedAddress, networkType, marketsArr) => {
    let totalBalance = 0
    let totalInterest = 0
    await Promise.all(marketsArr.map(async (market) => {
        const loanBalance = await getLoanBalance(web3, connectedAddress, networkType, market)
        const savingsBalance = await getSavingsBalance(web3, connectedAddress, networkType, market)
        const apy = await getApyRate(web3, networkType, market)
        if (loanBalance.loanBalance > 0 || savingsBalance.savingsBalance > 0) {
            totalBalance = totalBalance + savingsBalance.savingsBalanceFiat - loanBalance.loanBalanceFiat
            totalInterest = totalInterest + savingsBalance.savingsBalanceFiat * apy.savingsAPY / 100 - loanBalance.loanBalanceFiat * apy.loanAPY / 100
        }
    }))
    return (totalInterest / totalBalance) * 100
}

const getNetworkMarkets = (networkType) => {
    let marketsArr = Object.values(JSON.parse(JSON.stringify(Config.markets)));
    return !networkType ? [marketsArr] : marketsArr.filter(market => !!market.qToken.network[networkType])
}

const getCurrencyFormatted = (num, decimals = 2, dollar = '$') => {
    if (num == null) {
        return dollar + 0;
    }
    num = parseFloat(num)
    let si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: " K" },
        { value: 1E6, symbol: " M" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return dollar + (num / si[i].value).toFixed(decimals).replace(rx, "$1") + si[i].symbol;
}

const getBorrowLimit = async (web3, networkType, market) => {
    const collateralFactorMantissa = new BigNumber(ContractCallResult[getMulticallKey(market, "compoundLens.cTokenMetadataExpand")][0].hex).toString(10)
    const collateralFactor = collateralFactorMantissa / 1e18
    const price = await getPrice(web3, networkType, market);
    let borrowLimit = 0
    if (market.savingsBalance) {
        borrowLimit = market.collateralStatus ? collateralFactor * market.savingsBalance : 0
    }
    borrowLimit = toFixed(borrowLimit)
    if (CoreData.isNativeToken(market.symbol, networkType)) {
        const borrowLimitFormatted = CoreData.fromWei(web3, borrowLimit.toString(), 'ether');
        const borrowLimitFiat = borrowLimitFormatted * price

        return {
            borrowLimit: borrowLimit,
            borrowLimitFormatted: borrowLimitFormatted,
            borrowLimitFiat: borrowLimitFiat,
            collateralFactor: collateralFactor
        }
    }
    else {
        const decimals = await getDecimals(web3, networkType, market);
        const borrowLimitFormatted = (borrowLimit / Math.pow(10, parseInt(decimals)))
        const borrowLimitFiat = borrowLimitFormatted * price

        return {
            borrowLimit: borrowLimit,
            borrowLimitFormatted: borrowLimitFormatted,
            borrowLimitFiat: borrowLimitFiat,
            collateralFactor: collateralFactor
        }
    }
}

const getTotalBorrowLimit = async (marketsArr) => {
    let totalBorrowLimitFiat = 0
    await Promise.all(marketsArr.map(async (market) => {
        totalBorrowLimitFiat = totalBorrowLimitFiat + market.borrowLimitFiat
    }))
    //NEED TO VERIFY CALCULATION
    return totalBorrowLimitFiat
}

const getTotalMarketCap = async (marketsArr) => {
    let totalSupplyFiat = 0
    await Promise.all(marketsArr.map(async (market) => {
        totalSupplyFiat = totalSupplyFiat + market.totalSupplyFiat
    }))
    //NEED TO VERIFY CALCULATION
    return totalSupplyFiat
}

const getTotalBizSize = async (marketsArr) => {
    let totalBorrowedFiat = 0
    await Promise.all(marketsArr.map(async (market) => {
        totalBorrowedFiat = totalBorrowedFiat + market.totalBorrowedFiat
    }))
    let totalMktSize = await getTotalMarketCap(marketsArr)
    let totalBizSize = totalMktSize + totalBorrowedFiat
    return totalBizSize
}

const getTotalTVL = async (marketsArr) => {
    let totalTVLFiat = 0
    await Promise.all(marketsArr.map(async (market) => {
        totalTVLFiat = BigNumber(totalTVLFiat).plus(market.totalSupplyFiat)
    }))

    return {
        totalTVLFiat: +totalTVLFiat,
    }
}


const getTotalBorrow = async (marketsArr) => {
    let result = 0
    await Promise.all(marketsArr.map(async (market) => {
        result = BigNumber(result).plus(market.totalBorrowedFiat)
    }))

    return {
        totalBorrow: +result,
    }
}

const checkMembership = async (web3, connectedAddress, networkType, market) => {
    const isAssetMember = ContractCallResult[getMulticallKey(market, "checkMembership", connectedAddress)][0]
    return isAssetMember
}

const checkMintPaused = async (web3, networkType, market) => {
    let isMintPaused = ContractCallResult[getMulticallKey(market, "mintGuardianPaused")][0]
    return isMintPaused
}

const checkBorrowPaused = async (web3, networkType, market) => {
    let isBorrowPaused = ContractCallResult[getMulticallKey(market, "borrowGuardianPaused")][0]
    return isBorrowPaused
}

const getTotalBorrowed = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getTotalBorrowed`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }

    if (market.isLPToken) {
        return {
            totalBorrowed: '0',
            totalBorrowedFormatted: 0,
            totalBorrowedFiat: 0
        }
    }

    const price = await getPrice(web3, networkType, market);
    const totalBorrowed = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.totalBorrowsCurrent")][0].hex).toString(10)
    const totalBorrows = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.totalBorrows")][0].hex).toString(10)
    if (CoreData.isNativeToken(market.symbol, networkType)) {
        const bnTotalBorrowed = new BN(totalBorrowed)
        const totalBorrowedFormatted = web3.utils.fromWei(bnTotalBorrowed, 'ether');
        const totalBorrowedFiat = totalBorrowedFormatted * price
        return {
            // totalBorrowed: totalBorrowed,
            totalBorrowed: totalBorrows,
            totalBorrowedFormatted: totalBorrowedFormatted,
            totalBorrowedFiat: totalBorrowedFiat,
            totalBorrows
        }
    }
    else {
        const decimals = await getDecimals(web3, networkType, market);
        const totalBorrowedFormatted = (totalBorrowed / Math.pow(10, parseInt(decimals)))
        const totalBorrowedFiat = totalBorrowedFormatted * price
        return {
            // totalBorrowed: totalBorrowed,
            totalBorrowed: totalBorrows,
            totalBorrowedFormatted: totalBorrowedFormatted,
            totalBorrowedFiat: totalBorrowedFiat,
            totalBorrows
        }
    }
}

const getTotalSupply = async (web3, networkType, market) => {
    if (market.totalBorrowed && market.liquidity) {
        const theDenomination = parseFloat(market.totalBorrowed) + parseFloat(market.liquidity)
        return {
            totalSupply: (parseFloat(market.totalBorrowed) + parseFloat(market.liquidity)) + "",
            totalSupplyFiat: market.totalBorrowedFiat + market.liquidityFiat,
            totalSupplyFormatted: (parseFloat(market.totalBorrowedFormatted) + parseFloat(market.liquidityFormatted)) + "",
            utilRate: theDenomination === 0 ? 0 : ((parseFloat(market.totalBorrowed) / theDenomination) * 100)
        }
    } else {
        const totalBorrowedInfo = await getTotalBorrowed(web3, networkType, market);
        const liquidityInfo = await getLiquidityBalance(web3, networkType, market);
        const theDenomination = new BigNumber(totalBorrowedInfo.totalBorrowed).plus(liquidityInfo.liquidity).minus(liquidityInfo.totalReserves);

        return {
            totalSupply:
                parseFloat(totalBorrowedInfo.totalBorrowed) +
                parseFloat(liquidityInfo.liquidity) +
                '',
            totalSupplyFiat:
                totalBorrowedInfo.totalBorrowedFiat +
                liquidityInfo.liquidityFiat,
            totalSupplyFormatted:
                parseFloat(totalBorrowedInfo.totalBorrowedFormatted) +
                parseFloat(liquidityInfo.liquidityFormatted) +
                '',
            utilRate: theDenomination.eq(0) ? 0 : (new BigNumber(totalBorrowedInfo.totalBorrowed).multipliedBy(100).dividedBy(theDenomination))
        }
    }
}

const getExchangeRate = async (web3, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getExchangeRate`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }

    const exchangeRateCurrent = new BigNumber(ContractCallResult[getMulticallKey(market, "fToken.exchangeRateCurrent")][0].hex).toString(10)

    let decimals = 18
    if (!CoreData.isNativeToken(market.symbol, networkType)) {
        decimals = await getDecimals(web3, networkType, market);
    }
    const oneFTokenInUnderlying = exchangeRateCurrent / Math.pow(10, decimals);
    const exchangeRateFormatted = 1 / oneFTokenInUnderlying
    return {
        exchangeRateFormatted: exchangeRateFormatted
    }
}

function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

const pickFromMultiCallResults = (key, store, reference) => {
    if (!store) {
        return null;
    }

    const thePool = store.find(item => {
        if (reference) {
            return item.reference === reference
        } else {
            return item.methodName === key
        }
    })

    let values = null
    if (thePool) {
        values = thePool.returnValues
    } else {
        return null
    }

    let returns = []
    values.forEach(value => {
        if ((typeof value) === "string" || (typeof value) === "number") {
            returns.push(value)
        }

        if ((typeof value) === "object") {
            if (value.type === "BigNumber") {
                returns.push(new BigNumber(value.hex))
            }
        }
    });

    if (returns.length > 1) {
        return returns
    } else {
        return returns[0]
    }
}

const getTransactionLimit = async (web3, connectedAddress, networkType) => {
    if (networkType !== 'heco') {
        return -1
    } else {
        const theFlashLoanContract = Config.FlashLoanContract
        if (!theFlashLoanContract) {
            return null
        }

        const myContract = new web3.eth.Contract(FlashLoanTool.abi, theFlashLoanContract)
        const amount = await myContract.methods.getLiquidity().call()
        const decimals = await getDecimals(web3, networkType, Config.markets['HUSD']);
        const amountFiat = +BigNumber(amount).shiftedBy(-parseInt(decimals))
        return {
            amount,
            amountFiat
        }
    }
}


const getDecimalFromToken = async (web3, market, token) => {
    const Contract = await new web3.eth.Contract(market.ABI, token)
    const decimal = await Contract.methods.decimals().call()
    return decimal
}

const lpTotalAPYCaculate = async ({ mdxAPY, fildaAPY, mdxDepositAPY }) => {
    const n = 51264
    const BN = BigNumber.clone()
    BN.config({ DECIMAL_PLACES: 20, ROUNDING_MODE: 4, POW_PRECISION: 10 })

    const a = BN(mdxAPY).div(n)
    const x = BN(mdxDepositAPY).plus(fildaAPY).times(0.01).div(n).plus(1)

    let c = BN(1).minus(x.pow(n))
    let b = BN(1).minus(x)

    let r = c.div(b).times(a).times(100)
    return +r
}



const getLPData = async (web3, connectedAddress, networkType, market) => {
    let lpReward = []
    let lpTotalAPY = 0
    if (market.isLPToken) {
        const lpCTokenAddr = Config.markets[market.symbol].qToken.network[networkType].address
        const contract = await new web3.eth.Contract(Config.compoundLens.ABI, Config.compoundLens.network[networkType].address)

        // 读取奖励
        if (networkType === 'heco') {
            // 合约读取mdx奖励和filda奖励
            const lp = await CoreData.getQTokenContract(web3, networkType, market.symbol)
            const fildaToken = await lp.methods.comp().call()
            const fildaDecimal = await getDecimalFromToken(web3, market, fildaToken)
            const mdxToken = await lp.methods.mdx().call()
            const mdxDecimal = await getDecimalFromToken(web3, market, mdxToken)
            const rewardValue = await contract.methods.getLpRewardPending(lpCTokenAddr, connectedAddress).call()
            lpReward.push({
                name: 'MDX',
                symbol: 'MDX',
                uiSymbol: 'MDX',
                reward: +BigNumber(rewardValue.mdxReward).shiftedBy(-parseInt(mdxDecimal))
            }, {
                name: 'filda',
                symbol: 'filda',
                uiSymbol: 'filda',
                reward: +BigNumber(rewardValue.compReward).shiftedBy(-parseInt(fildaDecimal))
            })
        } else {
            if (Config.markets[market.symbol].lpRewardsTokens && Config.markets[market.symbol].lpRewardsTokens.length > 0) {
                let rewardValueArr = await contract.methods.getLpRewardPending(lpCTokenAddr, Config.markets[market.symbol].lpRewardsTokens.length, connectedAddress).call()
                for (let i = 0; i < rewardValueArr.length; i++) {
                    const { name, symbol, uiSymbol, logo, decimals } = Config.markets[market.symbol].lpRewardsTokens[i]
                    let reward = +BigNumber(rewardValueArr[i]).shiftedBy(-parseInt(decimals))
                    lpReward.push({
                        name,
                        symbol,
                        uiSymbol,
                        logo,
                        reward,
                    })
                }
            }

        }

        // APY
        if (networkType === 'heco') {
            // const HecoPoolAddr = ContractCallResult[getMulticallKey(market, "fToken.hecoPool", connectedAddress)][0]
            // const hecoPool = await new web3.eth.Contract(HecoPool.abi, HecoPoolAddr)
            // const pid = ContractCallResult[getMulticallKey(market, "fToken.pid", connectedAddress)][0]
            // const currentBlockNumber = await new web3.eth.getBlockNumber()
            // const mdxPerBlock = await hecoPool.methods.reward(currentBlockNumber).call()
            // const poolInfo = await hecoPool.methods.poolInfo(pid).call()
            // const totalAllocPoint = await hecoPool.methods.totalAllocPoint().call()
            // const lpTotal = poolInfo.totalAmount
            // const mdxPerSecondPershare = BigNumber(mdxPerBlock).div(lpTotal).div(3)
            // const mdxPrice = await getPrice(web3, networkType, Config.markets['MDX'])
            // const lpPrice = await getPrice(web3, networkType, market)

            // const mdxAPY = +BigNumber(mdxPrice).div(lpPrice).times(mdxPerSecondPershare).times(60 * 60 * 24 * 365).times(poolInfo.allocPoint).div(totalAllocPoint)
            // const mdxAPYData = await getApyRate(web3, networkType, Config.markets['MDX'])
            // const fildaAPY = mdxAPYData.savingsMintAPY
            // const mdxDepositAPY = mdxAPYData.savingsAPY

            // lpTotalAPY = await lpTotalAPYCaculate({ mdxAPY, fildaAPY, mdxDepositAPY })
        } else {
            if (Config.markets[market.symbol].lpRewardsTokens && Config.markets[market.symbol].lpRewardsTokens.length > 0) {
                const decimal = 8
                let lpPrice = await getPrice(web3, networkType, market)
                lpPrice = BigNumber(lpPrice).times(Math.pow(10, decimal)).toFixed(0)

                let priceArr = []
                for (let i = 0; i < Config.markets[market.symbol].lpRewardsTokens.length; i++) {
                    const lpRewardsSymbol = Config.markets[market.symbol].lpRewardsTokens[i].symbol
                    if (Config.markets[lpRewardsSymbol]) {
                        let price = await getPrice(web3, networkType, Config.markets[lpRewardsSymbol])
                        price = BigNumber(price).times(Math.pow(10, decimal)).toFixed(0)
                        priceArr.push(price)
                    } else {
                        // 奖励的Token不在markets中
                        // const glidePrice = new BigNumber(ContractCallResult['glide'][0].hex).toString(10)
                        // const price = BigNumber(glidePrice).times(Math.pow(10, 8)).div(Math.pow(10, 18)).toFixed(0)
                        const price = await getPriceViaChainlink(web3, networkType, Config.markets[market.symbol].lpRewardsTokens[i].network[networkType].address);
                        priceArr.push(price)
                    }
                }

                if (Config.markets[market.symbol].lpRewardsTokens.length === 1) {
                    // 单挖
                    // if (networkType === 'elamain') {
                    //     lpTotalAPY = await contract.methods.getGlideLpAPR(lpCTokenAddr, Config.markets[market.symbol].lpRewardsTokens[0].pid, priceArr[0], lpPrice).call()
                    // } else {
                    //     lpTotalAPY = await contract.methods.getQuickLpAPY(lpCTokenAddr, Config.dQuick, ...priceArr, lpPrice).call()
                    // }
                    lpTotalAPY = await getLPAPY(web3, networkType, market);
                } else {
                    // 双挖
                    // const lpTotalAPYJson = await contract.methods.getQuickDualLpAPY(lpCTokenAddr, Config.dQuick, ...priceArr, lpPrice).call()
                    // for (let key in lpTotalAPYJson) {
                    //     if (isNaN(parseInt(key))) {
                    //         continue;
                    //     }
                    //     lpTotalAPY += parseInt(lpTotalAPYJson[key])
                    // }
                }
                if (networkType === 'elamain') {
                    lpTotalAPY = +BigNumber(lpTotalAPY).times(365).div(Math.pow(10, 8)).times(100)
                } else {
                    const valueA = BigNumber(lpTotalAPY).dividedBy(Math.pow(10, 8))
                    lpTotalAPY = +(((BigNumber(1).plus(valueA))).pow(364).minus(1)).times(100)  // ((1+valueA)**364 -1) * 100
                }
            }
        }
    }

    return {
        lpReward,
        lpTotalAPY
    }
}

// 获得借款和存款限额(heco除外)
const getMaticCap = async (web3, connectedAddress, networkType, market) => {
    const key = `${networkType}|${market.symbol}|getMaticBorrowCap`
    if (MarketDataCache[key]) {
        return MarketDataCache[key]
    }
    const price = await getPrice(web3, networkType, market)
    const decimals = await getDecimals(web3, networkType, market)

    const borrowCap = new BigNumber(ContractCallResult[getMulticallKey(market, "QsConfig.getBorrowCap", connectedAddress)][0].hex).toString(10)
    const borrowCapFormatted = +BigNumber(borrowCap).shiftedBy(-parseInt(decimals))
    const borrowCapFiat = +BigNumber(borrowCapFormatted).times(price)

    const supplyCap = new BigNumber(ContractCallResult[getMulticallKey(market, "QsConfig.getSupplyCap", connectedAddress)][0].hex).toString(10)
    const supplyCapFormatted = +BigNumber(supplyCap).shiftedBy(-parseInt(decimals))
    const supplyCapFiat = +BigNumber(supplyCapFormatted).times(price)

    return {
        borrowCap,
        borrowCapFormatted,
        borrowCapFiat,
        supplyCap,
        supplyCapFormatted,
        supplyCapFiat
    }
}


export default {
    clearMarketDataCache: clearMarketDataCache,
    cacheAllMarketData: cacheAllMarketData,
    getWalletBalance: getWalletBalance,
    getGaugeAPY,
    getApyRate: getApyRate,
    getDecimals: getDecimals,
    getLiquidityBalance: getLiquidityBalance,
    getPrice: getPrice,
    getSupplyRewardsAPR,
    getBorrowRewardsAPR,
    getSavingsBalance: getSavingsBalance,
    getTotalSavingsBalance: getTotalSavingsBalance,
    getFiatValue: getFiatValue,
    getAccountLiquidity: getAccountLiquidity,
    getCollateralStatus: getCollateralStatus,
    getLoanBalance: getLoanBalance,
    getTotalLoanBalance: getTotalLoanBalance,
    getTotalSavingsAPY: getTotalSavingsAPY,
    getTotalLoanAPY: getTotalLoanAPY,
    getNetAPY: getNetAPY,
    getNetworkMarkets: getNetworkMarkets,
    getCurrencyFormatted: getCurrencyFormatted,
    getBorrowLimit: getBorrowLimit,
    getTotalBorrowLimit: getTotalBorrowLimit,
    checkMembership: checkMembership,
    getTotalBorrowed: getTotalBorrowed,
    getTotalSupply: getTotalSupply,
    getTotalBizSize: getTotalBizSize,
    getFildaPrice: getFildaPrice,
    getTokenUSDPriceViaSwapPair,
    getDogPrice: getDogPrice,
    checkMintPaused: checkMintPaused,
    checkBorrowPaused,
    getAccountAllowance,
    getSwapRepayAllowance,
    getDepositRepayAllowance,
    getDepositSwapAllowance,
    getLiquidateAllowance,
    getExchangeRate,
    getInterestRateModel,
    getReserveFactor,
    getCloseFactor,
    getLiquidationIncentive,
    getMarketPercentage,
    multiCaller,
    callContract,
    pickFromMultiCallResults,
    getTransactionLimit,
    getPriceInFilDA,
    getTotalTVL,
    getTotalBorrow,
    getLPData,
    getMaticCap,
    getTokenPriceInUSD,
    getUSDTInUSD,
}
