import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Config from '../../utils/config'
import { switchToWallet } from '../../methods/Wallet'
import { TorToast } from "../littleComponents"
import ToastError from '../../images/freshBank/toastError.svg'
import styles from './ConnectWalletModal.module.scss'
import { globalUtils } from '../../utils/globalUtils'

function ConnectWalletModal(props) {
    const { t } = useTranslation()
    const { wallet } = Config.headerConfigData;
    const [showMetamaskTips, setShowMetamaskTips] = useState(false)
    const [walletType, setWalletType] = useState('');

    const hasWallet = walletTitle => {
        let hasIt = false;
        switch (walletTitle) {
            case "metamask":
                hasIt = Boolean(window.ethereum);
                break;

            case "bitKeep":
                hasIt = Boolean(window.bitkeep && window.bitkeep.ethereum);
                break;

            case "tokenPocket":
                hasIt = window.ethereum.isTokenPocket;
                break;

            case "kucoinWallet":
                hasIt = window.kucoin?.isKuCoinWallet;
                break;

            default:
                break;
        }
        return hasIt;
    };

    const handleConnectWallet = async (event, key) => {
        if ((!hasWallet(key)) && key !== "walletconnect") {
            setWalletType(key)
            setShowMetamaskTips(true)
        } else {
            window.localStorage.setItem(globalUtils.WALLET_CONNECTING, globalUtils.ConnectStatus.CONNECTED);
            switchToWallet(event.currentTarget.id);
        }
        props.onHide();
    };

    let result = []
    for (let [key, value] of Object.entries(wallet)) {
        // since KuCoin Wallet only support mobile site 
        // if (value.name === 'KuCoin Wallet' && window.innerWidth > 991.98) continue;
        result.push(
            (<div
                id={key}
                key={key}
                variant="grey"
                onClick={(e) => handleConnectWallet(e, key)}
                className={styles.button}>
                <img
                    src={value?.modalImg}
                    alt={`${value?.name} Logo`} />
                <div className={styles.msg} >
                    {value?.name}
                </div>
            </div>));
    }

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                className={classNames("customModal", styles.connectWalletModal)}
                centered
            >
                <Modal.Header closeButton>
                    <h2>{t('Header.ConnectWalletModal.ConnectYourWallet')}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="contentContainer">
                        <div className={classNames("formContainer", styles.formWrap)}>
                            <div className={styles.desc}>{t('Header.ConnectWalletModal.ConnectYourWalletDesc')}</div>
                            <div className={styles.btnGroup}>
                                {result}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <TorToast
                type='error'
                torClassName={styles.metamaskToast}
                show={showMetamaskTips}
                onClose={() => setShowMetamaskTips(false)}
            >
                <dl>
                    <dt>
                        <img src={ToastError} />
                        <span>{t('Common.WalletProviderError')}</span>
                    </dt>

                    {wallet && walletType && wallet[walletType].url && <dd>
                        <a href={wallet[walletType].url} target="_blank" rel="noopener noreferrer">{t('Common.WalletProviderErrorTips')}</a>
                    </dd>}
                </dl>
            </TorToast>
        </>

    )
}

export default ConnectWalletModal